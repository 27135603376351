export default class Accordion {
  constructor(accordion) {
    this._element = accordion;
    // может быть развернуть только один элемент
    this.showOne = accordion.classList.contains('accordion-show-one');
    this._titles = this._element.querySelectorAll(`.accordion__title`);
    this._activeItem = this._element.querySelector(`.accordion__item.active`);
    this._activeItemContent = this._activeItem ? this._activeItem.querySelector(`.accordion__content`) : null;

    this._titleClickHandler = this._titleClickHandler.bind(this);
    this._windowResizeHandler = this._windowResizeHandler.bind(this);

    this.init()
  }

  _setCssProperty(value, customVar = `--faq-content-max-height`) {
    document.documentElement.style.setProperty(
        `${customVar}`,
        `${value}px`
    );
  }

  _titleClickHandler(evt) {
    const closestItem = evt.currentTarget.closest(`.accordion__item`);
    const closestContent = closestItem.querySelector(`.accordion__content`);
    const closestContentHeight = closestContent.scrollHeight;
    this._setCssProperty(closestContentHeight);


    if (this._activeItem) {
      if(this.showOne) {
        this._activeItem.classList.remove(`active`);
      }
      if (closestItem !== this._activeItem) {
        closestItem.classList.toggle(`active`);
        this._activeItem = closestItem;
        this._activeItemContent = closestContent;
      } else {
        closestItem.classList.toggle(`active`);
        this._activeItem = null;
      }
    } else {
      closestItem.classList.toggle(`active`);
      this._activeItem = closestItem;
      this._activeItemContent = closestContent;
    }
  }

  _windowResizeHandler() {
    if(this._activeItemContent) {
      this._setCssProperty(this._activeItemContent.scrollHeight);
    }
  }

  init() {
    if(this._activeItemContent) {
      this._setCssProperty(this._activeItemContent.scrollHeight);
    }

    this._titles.forEach((title) => title.addEventListener(`click`, this._titleClickHandler));
    window.addEventListener(`resize`, this._windowResizeHandler);
  }
}

import '~/assets/scss/main.scss'
import Accordion from '~/js/modules/accordion'
import getSwiperSlider from '~/js/modules/slider'
import '~/js/modules/tabs'
import MicroModal from 'micromodal/dist/micromodal.min'

window.addEventListener(`DOMContentLoaded`, () => {
  const accordions = document.querySelectorAll(`.accordion`)
  const accordionImageBoxes = document.querySelectorAll('.js-accordion-image')
  const mobileImageBox = document.querySelector('.js-mobileapp-image')
  const mobileAnimatedScrollBoxes = document.querySelectorAll('.js-mobile-scroll-animation')
  const feedbackContainer = document.querySelector(`.js-feedback-form`)
  const switchFormButtons = document.querySelectorAll(`.js-switch-window`)
  const fileList = document.querySelector('.js-files-list')
  const deleteFilesBtn = document.querySelector('.js-delete-files')

  if (feedbackContainer) {
    const feedbackForm = feedbackContainer.querySelector(`form`)
    const resetFields = feedbackForm.querySelectorAll('.form__input')
    const fileUpload = feedbackContainer.querySelector('.js-file-upload')
    const formFileTitle = fileUpload.querySelector('.js-file-title')
    const formInput = fileUpload.querySelector('.js-file-input')
    const successForm = feedbackContainer.querySelector('.form__success-content-wrapper')
    const inputCheckbox = feedbackContainer.querySelector('.js-input--checkbox')
    const filesList = feedbackContainer.querySelector('.js-files-list')
    const deleteFiles = feedbackContainer.querySelector('.js-delete-files')

    if (switchFormButtons) {
      switchFormButtons.forEach((el) => {
        el.addEventListener('click', () => {
          feedbackContainer.querySelector(`.js-side-form`).classList.toggle(`hidden`)
          const submitBtn = feedbackContainer.querySelector('.js-submit-btn')
          if (submitBtn) {
            submitBtn.style.display = 'block'
          }
          if (feedbackForm.style.display == 'none') {
            feedbackForm.style.display = 'block'
          }
          if (successForm.classList.contains('active')) {
            //если закрыли форму после успешной отправки, надо сбросить
            successForm.classList.remove('active')
            if (feedbackForm.style.display == 'none') {
              feedbackForm.style.display = 'block'
            }
          }

          resetFields.forEach(rf => {
            rf.classList.remove('invalid')
            rf.setAttribute('data-invalid', '')
            if (rf.querySelector('input')) {
              rf.querySelector('input').value = ''
            }
            if (rf.querySelector('textarea')) {
              rf.querySelector('textarea').value = ''
              rf.querySelector('textarea').style.height = '72px'
              rf.querySelector('.counter-comment__current').textContent = '0'
            }
          })
          if (fileList) {
            fileList.innerHTML = ''
          }
          if (deleteFilesBtn) {
            deleteFilesBtn.innerHTML = ''
          }
          if (inputCheckbox) {
            inputCheckbox.checked = false
          }
          if (filesList) {
            filesList.removeAttribute('disabled')
          }
          if (deleteFiles) {
            deleteFiles.removeAttribute('disabled')
          }
          formFileTitle.innerHTML = 'Прикрепить файлы&nbsp;&nbsp; <small class="file__title-subtitle">— не более 4 файлов</small>'
          formInput.value = ''
          feedbackForm.querySelector('.js-error_file').innerHTML = ''

            // Антиспам
          feedbackContainer.querySelector('.js-feedback_main').insertAdjacentHTML('beforeend','<input type="text" name="org" value="" class="_org" style="visibility:hidden; height: 0; width: 0; padding: 0; border:none;"/>')
            // Антиспам х

        })
      })
    }
  }

  const movePicture = (box) => {
    box.classList.add(`activated-by-scroll`)
    setTimeout(() => { box.classList.remove(`activated-by-scroll`) }, 500 )
  }

  if (accordions.length) {
    accordions.forEach((accordion) => {
      if (!accordion.classList.contains('main-nav__list')) {
        new Accordion(accordion)
        /* смена картинки при клике в аккордеоне */
        const imgContainer = document.querySelector(`.js-accordion-image`)
        accordion.addEventListener('click', () => {
          if (window.innerWidth <= 1024) {
            accordion.scrollIntoView();
          }
        })
      }

    })

  }

  const isInViewport = (el) => {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  document.addEventListener('scrollend', function () {


    accordionImageBoxes.forEach((accordionImageBox) => {
      if (isInViewport(accordionImageBox)) {
        movePicture(accordionImageBox);
      }
    })
    if(isInViewport(mobileImageBox)) {
      mobileImageBox.classList.add(`activated-by-scroll`)
      setTimeout(()=>{ mobileImageBox.classList.remove(`activated-by-scroll`) }, 4000 )
    }
    mobileAnimatedScrollBoxes.forEach((box) => {
      if (window.innerWidth <= 1024) {
        if (isInViewport(box)) {
          movePicture(box);
        }
      }
    })
  }, {
    passive: true
  })

  const bannersSlider = document.querySelector(`.js-main-slider`)
  if (bannersSlider) {
    const bannersSwiperParams = {
      speed: 1000,
      slidesPerView: 1,
      loop: true,
      watchSlidesProgress: true,
      touchStartForcePreventDefault: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },
      pagination: {
        el: `.main-slider-dots`,
        clickable: true,
        bulletClass: `main-slider-dots__dot`,
        bulletActiveClass: `active`,
        renderBullet(index, className) {
          return `
                <button class="${className}">
                </button>`;
        },
      },

      on: {
        touchStart() {
          this.autoplay.stop();
          document.querySelector('.swiper-pagination-bullets .active').classList.add('paused');
        },
        touchEnd() {
          this.autoplay.start();
          document.querySelector('.swiper-pagination-bullets .active').classList.remove('paused');
        },
        slideChangeTransitionEnd() {
          mobileAnimatedScrollBoxes.forEach((box) => {
            movePicture(box);
          })
        },
        slideChange() {
          this.autoplay.start();
          let pausedElement = document.querySelector('.swiper-pagination-bullets .paused');
          if (pausedElement) {
            pausedElement.classList.remove('paused');
          }
        }
      }
    }
    getSwiperSlider(bannersSlider, bannersSwiperParams)
  }

  const ratingObjectsSlider = document.querySelector(`.js-rating-slider-cards`)
  const ratingObjectsSwiperParams = {
    slidesPerView: `1.1`,
    spaceBetween: 20,
    loop: false,
    slidesOffsetAfter: 20,
    slidesOffsetBefore: 20,
    autoplay: false,
    cache: false,
    direction: `horizontal`,
    pagination: {
      el: `.slider-dots`,
      clickable: true,
      bulletClass: `slider-dots__dot`,
      bulletActiveClass: `active`,
      renderBullet(index, className) {
        return `
                <button class="${className}"></button>`;
      },
    },
    breakpoints: {
      1024: {
        slidesPerView: 3.1,
        spaceBetween: 20,
      }
    }
  };

  if (ratingObjectsSlider) {
    getSwiperSlider(ratingObjectsSlider, ratingObjectsSwiperParams)
  }

  const bannerCardsSlider = document.querySelector(`.js-banner-cards`);
  const bannerCardsSwiperParams = {
    wrapperClass: 'wrapper',
    slideClass: 'js-card-slide',
    spaceBetween: 2,
    slidesPerView: '2',
    loop: false,
    autoplay: {
      delay: 1000,
    }
  };
  if (bannerCardsSlider && window.innerWidth < 960) {
    getSwiperSlider(bannerCardsSlider, bannerCardsSwiperParams);
  }

  const teamImageBlock = document.querySelector('.js-team-scroll')
  teamImageBlock.addEventListener('touchstart', function () {
    teamImageBlock.querySelector('.mobile-tooltip').classList.add('hidden')
  })
  teamImageBlock.addEventListener('touchend', function () {
    teamImageBlock.querySelector('.mobile-tooltip').classList.remove('hidden')
  })
  if ((window.innerWidth <= 1024)) {
    teamImageBlock.scrollLeft = teamImageBlock.querySelector('img').offsetWidth / 2 - window.innerWidth / 2;
  }

  const registrationAutoFill = document.querySelector('.js-registration-autofill')
  if (registrationAutoFill) {
    registrationAutoFill.addEventListener('submit', function (e) {
      e.preventDefault()

      const registrationForm = document.querySelector('form[data-form-name="fullname_form"]')

      const inputName = registrationForm.querySelector('input[name="fname"]')
      const inputMail = registrationForm.querySelector('input[name="mail"]')

      const inputAutofillName = registrationAutoFill.querySelector('input[name="name-autofill"]')
      const inputAutofillMail = registrationAutoFill.querySelector('input[name="mail-autofill"]')

      let mailmask = inputAutofillMail.value.split('@')[1]
      if (inputAutofillName.value && inputAutofillName.value.length >= 2 &&
        inputAutofillMail.value && mailmask && mailmask.split('.')[1]) {
        //фокус чтобы плейсхолдеры не накладывались на значения
        inputName.parentElement.classList.add('active');
        inputMail.parentElement.classList.add('active');

        //данные из формы попадают в регистрацию research team
        inputName.value = inputAutofillName.value;
        inputMail.value = inputAutofillMail.value;

        //чтобы открылся сразу нужный таб с регистрацией
        const analyticsProfile = document.getElementById('modal-analytics-profile');
        MicroModal.show('modal-analytics-profile');
        analyticsProfile.querySelector(`[data-tab=tab2]`).click();
      }
    })
  }
  const accordionChangingImages = document.querySelectorAll('.js-change-accordion-image');
  const changedImages = document.querySelectorAll('.js-investment-images img');

  if (accordionChangingImages) {
    accordionChangingImages.forEach((changeImage) => {
      changeImage.addEventListener('click', function (e) {
        let index = this.getAttribute('data-image');
        changedImages.forEach((image) => {
          image.setAttribute('hidden', true);
        })
        changedImages[index - 1].removeAttribute('hidden');
        movePicture(changedImages[0].parentElement);
      })
    })
  }



  //проверяем размер файла, не больше 5мб
  const fileInputs = [];
  const fileInputService = document.querySelector('.js-file-input')

  if (fileInputService)
    fileInputs.push(fileInputService)

  fileInputs.forEach((fileInput) => {
    const fileInputField = fileInput.parentElement.parentElement.parentElement
    const formContent = fileInput.parentElement.parentElement.parentElement.parentElement
    const fileCountLabel = fileInputField.querySelector('.js-file-count')
    const errorFileUpload = formContent.querySelector('.js-error_file')
    const fileCountLabelDefault = fileCountLabel.innerHTML
    const fileAttentionLabel = fileInputField.parentElement.querySelector('.js-file-info')
    const confirmSizeLimitWindow = fileInputField.parentElement.querySelector('.confirm-sizelimit')
    const fileInfoList = fileInputField.parentElement.querySelector('.js-files-list')
    let fileList = [];

    fileInput.onclick = function () {
      let $this = this;
      fileList = $this.files
    }

    fileInput.addEventListener('change', function() {
      let $this = this;
      let isSizeOverLimit = false;
      let isFileCountOverLimit = false;
      let isFileType = false;
      let newFileList = new DataTransfer()
      let fileLength = 0
      if (fileList.length > 4) {
        fileLength = 4
      } else {
        fileLength = fileList.length
      }

      for (let i = 0; i < fileLength; i++) {
        newFileList.items.add(fileList[i])
      }
      // if(newFileList.files.length < 4) {
      for (let i = 0; i < $this.files.length; i++) {
        if(newFileList.files.length < 4) {
          if ($this.files[i].size < 5242880) { //не больше 5мбайт
            if ($this.files[i].type == 'image/jpeg' || $this.files[i].type == 'image/png') { // правильные форматы
              newFileList.items.add($this.files[i])
              isFileType = false
              isSizeOverLimit = false
            } else {
              isFileType = $this.files[i].name
            }
          }
          else {
            isSizeOverLimit = $this.files[i].name
          }
        }
        else {
          isFileCountOverLimit = true;
          break;
        }
      }
      // }
      $this.files = newFileList.files;

      fileCountLabel.innerHTML = `Прикреплено файлов: ${$this.files.length}`

      fileInputField.classList.remove('invalid')
      fileInput.setAttribute('data-value', '')

      //кнопка очистки списка загружаемых файлов
      let deleteBtn = fileInputField.parentElement.querySelector('.js-delete-files')
      deleteBtn.innerHTML = ''
      if (isSizeOverLimit) {
        errorFileUpload.classList.add('active')
        errorFileUpload.innerHTML = errorFileUpload.innerHTML + `<br><span style="color: #DA111B">Ошибка загрузки <b> ${isSizeOverLimit} </b> – размер файла больше 5Мб</span>`
        if (confirmSizeLimitWindow) {
          confirmSizeLimitWindow.classList.add('active')
        }
      } else if (isFileType) {
        errorFileUpload.classList.add('active')
        errorFileUpload.innerHTML = errorFileUpload.innerHTML + `<br><span style="color: #DA111B">Ошибка загрузки <b> ${isFileType} </b> – недопустимый формат файла</span>`
        if (confirmSizeLimitWindow) {
          confirmSizeLimitWindow.classList.add('active')
        }
      } else {
        errorFileUpload.classList.remove('active')
        fileAttentionLabel.classList.remove('invalid')
        fileInputField.classList.remove('invalid')
        if (confirmSizeLimitWindow) {
          confirmSizeLimitWindow.classList.remove('active')
        }
      }
      fileInfoList.innerHTML = ""
      for (let i = 0; i < $this.files.length; i++) {
        const fileinfo = document.createElement('div')
        const filename = document.createElement('span')
        const filedel = document.createElement('a')
        filedel.innerHTML = '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          '<path d="M15.4549 6.34375H4.54688V18.0497H15.4549V6.34375ZM14.2549 16.8497H5.74687V7.54375H14.2549V16.8497Z" fill="#75808F"/>' +
          '<path d="M3.08594 5.07819H16.9179V3.87819H12.6619V1.9502H7.34194V3.87819H3.08594V5.07819ZM8.54194 3.1502H11.4619V3.87819H8.54194V3.1502Z" fill="#75808F"/>' +
          '<path d="M8.59844 9.40186H7.39844V14.8579H8.59844V9.40186Z" fill="#75808F"/>' +
          '<path d="M12.5984 9.40186H11.3984V14.8579H12.5984V9.40186Z" fill="#75808F"/>' +
          '</svg>'
        filename.innerHTML = $this.files[i].name
        filedel.setAttribute('data-index',i)
        fileInfoList.appendChild(fileinfo)
        fileinfo.appendChild(filename)
        fileinfo.appendChild(filedel)

        filedel.addEventListener('click', () => {
          fileCountLabel.innerHTML = `Прикреплено файлов: ${$this.files.length-1}`
        })

      }
      fileInfoList.onclick = function(e) {
        const tempFileList = new DataTransfer()
        const fileindex = e.target.closest('a').dataset.index;
        if (e.target.closest('a')) {
          for (let i = 0; i < $this.files.length; i++) {
            if(i != fileindex) {
              tempFileList.items.add($this.files[i]);
            }
          }
          $this.files = tempFileList.files;
          e.target.closest('a').parentElement.remove()
          if ($this.files.length < 1) {
            deleteBtn.innerHTML = ''
          }
          const alist = fileInfoList.querySelectorAll('div')
          for (let i = 0; i < alist.length; i++) {
            alist[i].querySelector('a').setAttribute('data-index',i)
          }
        }
      }

      if (fileCountLabel && $this.value) {
        deleteBtn.innerHTML = "Очистить"
        fileCountLabel.style.color = '#0E0F11BF'

        deleteBtn.onclick = function () {
          $this.value = "";
          fileInfoList.innerHTML = ""
          deleteBtn.innerHTML = ""
          fileInputField.classList.remove('invalid')
          fileInput.setAttribute('data-value', '')
          fileCountLabel.innerHTML = fileCountLabelDefault
          fileCountLabel.style.color = '#e73760'
        }
      } else {
        fileCountLabel.style.color = '#e73760'
      }
    })
    if (confirmSizeLimitWindow) {
      confirmSizeLimitWindow.addEventListener('click', function (e) {
        confirmSizeLimitWindow.classList.remove('active');
        fileAttentionLabel.classList.remove('invalid');
      })
    }
  })





});

//необходима дальнейшая переработка
/*let shadowBlocks = document.querySelectorAll(".js-shadow-block");
if (shadowBlocks) {
    shadowBlocks.forEach((block) =>
        block.onmousemove = function (e) {
            let img = block.querySelector(".shadow");
            img.style.top = e.offsetY + 55 + "px";
            img.style.left = e.offsetX + 26 + "px";
        }
    )
}*/

const socialTimeoutLinks = document.querySelectorAll('.js-social-link-timeout');
if ((socialTimeoutLinks) && (window.innerWidth <= 1024)) {
  socialTimeoutLinks.forEach((link) =>
    link.onclick = function (e) {
      {
        e.preventDefault();
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = link.getAttribute('href');
        setTimeout(() => a.click(), 800);
      }
    }
  )
}
